import React, { useEffect, useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import IconWrapper from "../Common/IconWrapper/IconWrapper.jsx";
import styles from "./report.module.css";

import {
  columnForEKYCTable,
  columnForReleaseReport,
  columnForVendorTable,
  columnforCreditBalance,
  columnforCreditBalanceDebit,
} from "../Utils/ColumnForReports.js";

import {
  FETCH_RELEASE_BY_MID,
  FETCH_ALL_VENDERS_BY_MID,
  GET_ALL_CREDIT,
  GET_ALL_DEBIT,
  FETCH_ALL_EKYC,
} from "../Utils/Constants.js";
import useApiServices from "../Utils/useApiService.js";
import {
  config,
  getCompleteDomain,
  getServerEnvironment,
} from "../Utils/DynamicPortalFunctions.js";
import Shimmer from "../Common/Shimmer/Shimmer.jsx";
import Modal from "../Common/Modal/Modal.jsx";
import ViewVendor from "../Components/Tools/ViewVendor.jsx";
import { createDateFromTimestamp } from "../Utils/helper.js";
import { jwtDecode } from "jwt-decode";
import NoDataFound from "../Common/NoDataFound/NoDataFound.jsx";
import { useSelector } from "react-redux";
import Morefilter from "../Components/Morefilter/Morefilter.jsx";

/**
 * Release component renders Report page.
 * @description it takes the type then call api accordingbto the type and render the data into the table.
 * @returns {ReactNode} A React element that renders Report page.
 */
function Release({ type }) {
  const [data, setData] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [activeButton, setActiveButton] = useState(null);
  const [active, setActive] = useState("");
  const [count, setCount] = useState(14);
  const [vendorId, setVendorId] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [showFilter, setShowFilter] = useState(false);
  const [transactionDetails, setTransactionDetails] = useState({});
  const { apiCallFnforGet, apiCallFnforPost } = useApiServices();
  const { mid } = useSelector((state) => state.userData);

  const Columns = {
    Vendors: [
      ...columnForVendorTable,
      {
        headerName: "Actions",
        field: "active",
        minWidth: 500,
        renderCell: (params) => (
          <div>
            <button
              className="btn btn-sm btn-primary"
              data-bs-toggle="modal"
              data-bs-target="#ViewVendor"
              onClick={() => handleViewdetails(params.row.vendorId)}
            >
              View Details
            </button>
          </div>
        ),
      },
    ],
    Release: columnForReleaseReport,
    Ekyc: columnForEKYCTable,
    Credit:
      activeButton === 1 ? columnforCreditBalance : columnforCreditBalanceDebit,
  };

  const handleViewdetails = async (id) => {
    await setVendorId(id);
  };

  useEffect(() => {
    if (type === "Credit") {
      setActiveButton(1);
      setActive("Credit txn");
    }
  }, [type]);

  useEffect(() => {
    setData([]);
    switch (type) {
      case "Vendors":
        fetchVendorData();
        break;
      case "Credit":
        fetchCreditReport();
        break;
      case "Release":
        fetchReleaseData();
        break;
      case "Ekyc":
        fetchEkycData();
        break;
      default:
        setData([]);
    }
  }, [activeButton, type]);

  const fetchReleaseData = async () => {
    try {
      setShowLoader(true);
      const response = await apiCallFnforGet(FETCH_RELEASE_BY_MID + mid);

      if (response?.statusCode === 200) {
        const finalres = response.data.map((item, i) => ({
          id: i,
          mid: item.mid || "NA",
          amount: item.amount || "NA",
          rrn: item.rrn || "NA",
          txnDate: item.txnDate || "NA",
          releaseFrom: item.releaseFrom.toUpperCase() || "NA",
          createDate: item.createDate || "NA",
          createTime: item.createTime || "NA",
        }));

        setData(finalres);
        setShowLoader(false);
      } else {
        setData([]);
        setShowLoader(false);
      }
    } catch (error) {
      console.log(error?.message);
      setShowLoader(false);
    }
  };

  const fetchVendorData = async () => {
    try {
      setShowLoader(true);
      const response = await apiCallFnforGet(FETCH_ALL_VENDERS_BY_MID + mid);

      if (response?.statusCode === 200) {
        const finalres = response.data.map((item, i) => ({
          id: i,
          vendorId: item.vendorId,
          date: item.updateDate || "",
          fullName: item.fullName || "",
          email: item.email || "",
          userVPA: item.upi || "",
          vendorStatus: item.vendorStatus || "",
          mid: item.mid || "",
          mobile: item.mobile  || "",
          remark: item.remark || "",
        }));

        setData(finalres);
        setShowLoader(false);
      } else {
        setData([]);
        setShowLoader(false);
      }
    } catch (error) {
      console.log(error?.message);
      setShowLoader(false);
    }
  };

  const fetchEkycData = async () => {
    try {
      setShowLoader(true);
      const response = await apiCallFnforPost(FETCH_ALL_EKYC, {
        mid: mid,
      });

      if (response?.statusCode === 200) {
        const finalres = response.data.map((item, i) => ({
          id: i,
          date: createDateFromTimestamp(item?.requestDateAndTime),
          ...item,
        }));

        setData(finalres);
        setShowLoader(false);
      } else {
        setData([]);
        setShowLoader(false);
      }
    } catch (error) {
      console.log(error?.message);
      setShowLoader(false);
    }
  };

  const fetchCreditReport = async () => {
    try {
      setShowLoader(true);
      const response = await apiCallFnforGet(
        `${activeButton === 1 ? GET_ALL_CREDIT : GET_ALL_DEBIT}`
      );

      if (response?.statusCode === 200) {
        const finalres = response.data.map((item, i) => ({
          id: i,
          ...item,
          chargeInPercentage: item?.chargeInPercentage,
          deductedAmount: item?.deductedAmount,
          orignalAmount: item?.orignalAmount,
          serviceCharge: item?.serviceCharge,
          serviceChargePercentage: item?.serviceChargePercentage,
        }));

        setData(finalres);
        setShowLoader(false);
      } else {
        setData([]);
        setShowLoader(false);
      }
    } catch (error) {
      console.log(error?.message);
      setShowLoader(false);
    }
  };

  const incrementZoom = () => {
    {
      count < 28 && setCount(count + 1);
    }
  };

  const decrementZoom = () => {
    {
      count > 14 && setCount(count - 1);
    }
  };

  const { logoPath, Name } = config(getServerEnvironment(getCompleteDomain()));

  const handleToolsButtonClick = (type, buttonNumber) => {
    setActive(type);
    setActiveButton(buttonNumber);
  };

  const handleFiler = (type) => {
    setShowFilter(!showFilter);
  };

  const handleClose = (type) => {
    setShowFilter(false);
  };

  return (
    <>
      <div className={"container-fluid"}>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header  bg-white d-flex justify-content-between">
                <h3 className="card-title">{type} Report</h3>

                <div className="col-7 mb-4 mb-sm-0 text-right">
                  <IconWrapper title={"Zoomin"}>
                    <span className="" onClick={incrementZoom}>
                      <i className="fa-solid fa-magnifying-glass-plus text-dark"></i>
                    </span>
                  </IconWrapper>
                  <IconWrapper title={"Zoomout"}>
                    <span className="" onClick={decrementZoom}>
                      <i className="fa-solid fa-magnifying-glass-minus text-dark"></i>
                    </span>
                  </IconWrapper>
                  {/* {type === "Vendors" ? null : (
                    <IconWrapper title={"filter"}>
                      <span class="" onClick={() => handleFiler(type)}>
                        <i class="fa fa-filter text-dark"></i>
                      </span>
                    </IconWrapper>
                  )} */}
                </div>
              </div>
              {type === "Credit" ? (
                <div className="card-header  bg-white d-flex justify-content-between">
                  <ul className="d-flex gap-3 mb-2">
                    <li
                      className={activeButton === 1 ? "active" : ""}
                      onClick={() => handleToolsButtonClick("Credit txn", 1)}
                    >
                      <div
                        className={`btn ${
                          activeButton === 1
                            ? "btn-primary"
                            : "btn-outline-primary"
                        }`}
                      >
                        Credit Transactions
                      </div>
                    </li>

                    <li
                      className={activeButton === 2 ? "active" : ""}
                      onClick={() => handleToolsButtonClick("Credit added", 2)}
                    >
                      <div
                        className={`btn ${
                          activeButton === 2
                            ? "btn-primary"
                            : "btn-outline-primary"
                        }`}
                      >
                        Credit Added
                      </div>
                    </li>
                  </ul>
                </div>
              ) : null}

              <Modal
                id={"ViewVendor"}
                title={"View Vendor Details"}
                Width={"xl"}
              >
                <ViewVendor vendorId={vendorId} />
              </Modal>

              <div>
                {showLoader ? (
                  <Shimmer type="table" />
                ) : data?.length > 0 ? (
                  <div
                    className={`card-body no-scrollbar ${styles["DataGridHeightClass"]}`}
                  >
                    <DataGrid
                      className="custom-scrollbar"
                      rows={data}
                      columns={Columns[type]}
                      pagination
                      paginationMode="client"
                      page={page - 1}
                      pageSize={pageSize}
                      rowsPerPageOptions={[5, 10, 20, 50]}
                      onPageChange={(newPage) => setPage(newPage + 1)}
                      onPageSizeChange={(newPageSize) =>
                        setPageSize(newPageSize)
                      }
                      components={{
                        Toolbar: GridToolbar,
                      }}
                      hideFooterPagination={false}
                      sx={{
                        fontSize: `${count}px`,
                        "& .css-1rtad1": {
                          display: "block",
                        },
                      }}
                    />
                  </div>
                ) : (
                  <NoDataFound className="border border-3 border-primary" />
                )}
              </div>
            </div>
            <Morefilter
              active={active}
              type={type}
              setData={setData}
             
              showFilter={showFilter}
              handleClose={handleClose}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Release;
